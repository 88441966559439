import cookies from './inc/cookies.js';
import EventBus from './inc/event-bus.js';
import limitmotion from './inc/limit-data.js';
import limitdata from './inc/limit-motion.js';
import dialogPolyfillLoaded from './inc/dialog-polyfill-loader.js';
import globalnav from './inc/globalnav.js';
import { NavToggle, nextFocusableElement } from './inc/nav-toggle.js';
import supernav from './inc/supernav.js';
import search from './inc/search.js';


//Branding!!!
( function () {
	var a = [
		"MMMMMMMMMMMM      MMMMMMMMM \n",
		"  MMMMMMMMMMM    MMMMMMMMMM \n",
		"   MMMMMMMMMM   MMMMMMMMMM  \n",
		"   MMMMMMMMMM  MMMMMMMMMMM  \n",
		"  MMMMMMMMMMMMMMMMMMMMMMM   Monmouth University\n",
		"  MMMMMMMMMMMMMMMMMMMMMMM   Hawks Fly Together\n",
		" MMMMMM  MMMMMMMM  MMMMM    \n",
		" MMMMMM  MMMMMMM  MMMMMM    \n",
		"MMMMMM   MMMMMM  MMMMMM     \n" ].join( "" );
	console.log( a );
}() );

window.eventBus = new EventBus( 'mu-event-bus' );

// add a css helper so we know what the width of the scroll bar is.
document.addEventListener( "readystatechange", ( event ) => {
	if ( 'complete' === document.readyState ) {
		document.documentElement.style.setProperty( '--scrollbar-width', window.innerWidth - document.documentElement.clientWidth + 'px' );
	}
} );

window.eventBus.on( 'supernav-height-change', ( { detail } ) => {
	[].forEach.call( detail, ( supernav, i ) => {
		if ( i !== 0 ) {
			const currentMenuEl = supernav.querySelector( '.supernav-current' );
			supernav.style.minHeight = currentMenuEl.offsetHeight + 'px';
		}
	} );
} ); 


// enable JS interactions on the global nav
let globaNavLiEl = document.querySelectorAll( '.globalnav > ul > li' );
globaNavLiEl.forEach( ( item ) => {
	if (item.querySelector('button') != null) {
		item.navToggle = new NavToggle( item );
	}
} );

// convert a link into a button
function convertToButton( el ) {
	let b = document.createElement( 'button' );
	b.classList.value = el.classList.value;
	b.innerHTML = el.innerHTML;
	el.after( b );
	el.remove();
	return b;
}

// convert the resources A tag to a button for progressive enhancement and for more semantic html when JS takes care of the interactions. 
let resourcesButton = document.querySelector( '.resourcesbutton' );
let resourcesHolder = document.querySelector( '.resources' );

if ( resourcesButton ) {
	resourcesButton = convertToButton( resourcesButton );

	// we also need to remove the close link as the button will now do that job
	let closeresources = document.getElementById( 'closeresources' );
	closeresources.remove();

	// attach the event handlers
	resourcesButton.navToggle = new NavToggle( {
		'el': resourcesHolder,
		'button': resourcesButton,
		'nextElement': function () {
			let next = nextFocusableElement( document.querySelector(".mobile-showhide") );
			return next
		}
	} );
}


// setup the mobile nav


// convert the open and close links into buttons
let movileNav = document.getElementById( 'mobilenav' );
let movileNavOpen = document.querySelector( '.mobile-showhide' );
let movileNavClose = document.querySelector( '.navigator-close' );
let resourcesBack = document.getElementById( 'resourcesback' );

movileNavOpen = convertToButton( movileNavOpen );
movileNavClose = convertToButton( movileNavClose );
resourcesBack = convertToButton( resourcesBack );

movileNavOpen.navToggle = new NavToggle( {
	'el': movileNav,
	'button': movileNavOpen,
	'closeButton': movileNavClose,
	'altOpenButton': resourcesBack
} );

// fire a general event to notify other scripts that the primary one has loaded
const load2022 = new Event( 'load-2022' );
window.dispatchEvent( load2022 );


// send another event once we know the full page has loaded
document.addEventListener( "readystatechange", ( event ) => {
	if ( 'complete' === document.readyState ) {
		window.dispatchEvent( load2022 );
	}
} );
