const supernavs = document.querySelectorAll('.supernav');
var menus = {};

/**
 * Fetch and return the menu object for a specified URL
 * @param {string} menuURL The url of the menu to fetch
 * @returns promise
 */
const fetch_menu = menuURL => {
	// force https.
	menuURL = menuURL.replace('http://', 'https://');

	// the menu is already loaded on our page
	if (menus[menuURL]) {
		if (menus[menuURL].then) {
			return menus[menuURL];
		} else {
			return Promise.resolve(menus[menuURL]);
		}
	}

	// the menu exists in the local storage
	// TODO: enable this once a caching solution is in place
	/* 
	if ( localStorage.getItem( menuURL ) ) {
		return Promise.resolve( JSON.parse( localStorage.getItem( menuURL ) ) );
	}
	*/
	// fetch the menu from the API
	menus[menuURL] = fetch(menuURL)
		.then(function (response) {
			return response.json();
		})
		.then(function (json) {

			// TODO: enable this once a caching solution is in place
			// Add the menu item into the local storage
			// localStorage.setItem( menuURL, JSON.stringify( json ) );

			// Add the menu item into the menus object
			menus[menuURL] = json;
			return json;
		});
	return menus[menuURL];

}

/**
 * Handle the click event on the menu items
 * @param {event} event 
 */
function handle_menu_click(event) {
	let button,
		id,
		menuguid,
		direction;

	// find the root button
	if ('SPAN' === event.target.tagName) {
		button = event.target.parentNode;
	} else {
		button = event.target;
	}

	button.setAttribute('aria-expanded', 'true');

	id = Number(button.dataset.menuid);

	direction = (button.classList.contains('back')) ? 'back' : 'forward';

	if (("crosslink" in button.dataset)) {
		menuguid = button.dataset.crosslink;

		// if we are moving to a new sub menu then clear the current id as it wont match the new menus items.
		if ('forward' === direction) {
			id = null;
		}

	} else {
		// guid shared between the two supernavs, just grab the first one we find
		menuguid = document.querySelector('.supernav-current').dataset.menuguid;
	}
	render_menu(menuguid, id, direction);

}


/**
 * Convert a menu item to a html dom item. 
 * @param {string} menu The url of the menu to use
 * @param {object} menu_item The Menu item object
 * @param {string} extra_class Any extra classes to add to the menu item
 * @returns dom element
 */
function create_menu_element(menu, menu_item, extra_class, force_button = false) {

	if (!menu_item) return;


	let el,
		name = menu_item.name;

	if (extra_class === 'back') {
		name = 'Back';
	}

	if (extra_class === 'home') {
		name = 'Main Menu';
	}

	if ((menu_item.url === "#" || menu_item.url === "#/" || force_button) ) {
		if ( extra_class !== 'parent') {
			el = document.createElement('button');
			el.setAttribute('aria-expanded', 'false');
			el.classList.add('has-child');
			el.addEventListener('click', handle_menu_click);

			if (extra_class === 'back') {
				el.dataset.crosslink = menu;
			}
			if (extra_class === 'home') {
				el.classList.add('back');
			}
		} else {
			el = document.createElement('p');
			el.classList.add('has-child');
		}

	} else {

		el = document.createElement('a');
		el.href = menu_item.url.replace(/\/$/, '');

	}

	if ((has_children(menu, menu_item) && extra_class !== 'parent')) {
		el.innerHTML = '<span>' + name + '</span><svg viewBox="0 0 10.4 5.9" width="10.4" height="5.9" ><use xlink:href="#chevron"></use></svg>';
	} else {
		el.innerHTML = "<span>" + name + "</span>";
	}


	if (menu_item.class && menu_item.class.length > 0) {
		el.classList.add(menu_item.class);
	}

	el.dataset.menuid = menu_item.id;
	if (menu_item.url == supernavs[0].getAttribute('data-archiveurl')) {
		el.classList.add('current-page');
	} else if (menu_item.url === window.location.href) {
		el.classList.add('current-page');
	}

	if (menu_item.title) el.title = menu_item.title;
	if (menu_item.target) el.target = menu_item.target;

	if (null !== menu_item.crosslink) {
		el.classList.add('is-crosslink');
		el.dataset.crosslink = menu_item.crosslink;
	}

	el.classList.add(extra_class);

	return el;

}


/**
 * Does the menu item have children
 * @param {mixed} id The menu item id
 * @param {string} menu The URL of the menu to reference
 * @returns boolean
 */
function has_children(menu, id) {

	if (typeof id !== 'object') {
		menu_item = menus[menu].items.find(item => item.id == id);
	} else {
		menu_item = id;
	}

	if (!menu_item) return false;

	// if the item has a crosslink then it has children
	if (menu_item.crosslink) return true;

	// see if the item has any children in this menu
	if (menus[menu].items.filter(item => item.parentid === menu_item.id).length > 0) {
		return true;
	}

	return false;
}


/**
 * Will attempt to lookup the parent of the menu item selected. 
 * @param {string} menu The URL of the menu to reference
 * @param {int} pageID The ID of the menu item to find the parent of
 * @returns mixed
 */
function get_parent(menu, pageID) {
	// console.log('FIND PARENT OF', menu, pageID);

	// fetch the current item
	let page = menus[menu].items.find(item => item.id == pageID);

	// Unable to find the menu item for the ID
	if (!page) {
		// console.log("DID NOT FIND MENU ITEM");
		return Promise.resolve(null);
	}

	if (0 === page.parentid) {
		// The current item is at the root of its menu, and the menu has a crosslink.
		if (menus[menu].parent !== null) {

			return fetch_menu(menus[menu].parent)
				.then(newMenu => {
					let crosslink = newMenu.items.find(item => {
						if (!item.crosslink) return false;
						return item.crosslink.replace('http://', 'https://') === menu.replace('http://', 'https://');
					});

					// console.log(newMenu);
					let parent = newMenu.items.find(item => {
						if (typeof crosslink !== 'undefined') {
							return item.id === crosslink.parentid
						}
					});
					if (typeof parent !== 'undefined') {
						parent.menuguid = menus[menu].parent;
					} else {
						// otherwise show there is no parent
						parent = false;
					}

					return parent;
				});

		}

		// The current item is at the root of its menu, and the menu does not have a crosslink.
		return Promise.resolve(null);

	} else {// the current item is not at the root of the menu, find and return its parent

		parentMenuItem = menus[menu].items.find(item => item.id === page.parentid);
		return Promise.resolve(parentMenuItem);

	}

}


/**
 * Render the selected menu to the supernav div
 * @param {object} menuURL 
 * @param {int} activeMenuID 
 * @param {string} direction 
 */
const render_menu = (menuURL, activeMenuID, direction) => {
	menuURL = menuURL.replace('http://', 'https://');

	// console.log("RENDER MENU", menuURL, activeMenuID, direction);

	// TODO: Add a timeout to show a loading spinner if the response takes more then 250ms(subject to change).
	// TODO: Let the suernav go to the root of the site, note this should now be allowed on mobile. 

	fetch_menu(menuURL)
		.then(menu => {

			// something went really wrong
			if (!menu) {
				return;
			}

			if (0 === activeMenuID) {// if activeMenuID === 0 then we are looking for the homepage. 

				// console.log("RENDER: Clicked on the Main Menu Button");

				// if the current menu has a parent we are on a direct descendent menu, fetch the global menu. 
				// if (menus[menuURL].parent) {

				// 	fetch_menu(menus[menuURL].parent).then(root => {
				// 		let grandparent = null,
				// 			parent = null;
				// 		let children = root.items.filter(item => item.parentid === 0);

				// 		create_nav_panel(menus[menuURL].parent, direction, grandparent, parent, children);

				// 	});

				// }

				// Aug 12: If the main menu button is clicked, we're going home
				window.location.href = window.location.origin;
				return;

			} else {// looking for a normal menu, not the homepage.		

				// Root of menu selected, probably a crosslink, find the first item in the menu and use that. 
				if (null === activeMenuID) {
					activeMenuID = menu.items[0].id;
				}
				// find the grandparent of the clicked on menu item, might require a API round trip.
				get_parent(menuURL, activeMenuID, true).then(grandparent => {

					// attach the parent of the menu
					let parent = menu.items.find(item => item.id === activeMenuID);

					// attach the children
					let children = menu.items.filter(item => item.parentid === activeMenuID);

					create_nav_panel(menuURL, direction, grandparent, parent, children);

				});

			}

		});
}

/**
 * Create the naviation panel that will become active, remove the old one.
 * @param {string} menuURL The url of the menu
 * @param {string} direction The direction that we are navigating
 * @param {object} grandparent The menu item of the grandparent aka Back button
 * @param {object} parent the menu item of the parent
 * @param {array} children the children to append
 */
const create_nav_panel = (menuURL, direction, grandparent, parent, children) => {

	// is current supernav parent a button
	const curSupParent = document.querySelector('.supernav .parent');
	const curSPIsP = curSupParent ? curSupParent.tagName == 'P' : false;

	if (parent !== null && parent.url !== '#' && ( parent.url !== window.location.href && !curSPIsP ) ) {
		window.location.href = parent.url;
		return;
	}
	// go through both supernavs
	[].forEach.call(supernavs, supernav => {

		// get the current menu
		let currentMenuEl = supernav.querySelector('.supernav-current');

		// Check for the inactive menu
		let inactiveMenuEl = supernav.querySelector('.supernav-inactive');

		// if no element exists create it. 
		if (null === inactiveMenuEl) {
			inactiveMenuEl = document.createElement('div');
			supernav.appendChild(inactiveMenuEl);
		} else { // if it exists empty it.
			inactiveMenuEl.innerHTML = '';
		}

		// set the url for the current menu
		inactiveMenuEl.dataset.menuguid = menuURL;

		// prep the panels
		inactiveMenuEl.classList.remove('supernav-previous', 'supernav-next', 'supernav-inactive');
		currentMenuEl.classList.remove('supernav-previous', 'supernav-next');

		if (direction === 'forward') {
			inactiveMenuEl.classList.add('supernav-next');
		} else {
			inactiveMenuEl.classList.add('supernav-previous');
		}


		// if we have a grandparent item then add it in as a back button. 
		if (grandparent !== null && grandparent !== false) {
			if (parent.url === '#' || parent.url === '#/') {
				// console.log('parent_is_hash');
				inactiveMenuEl.appendChild(
					create_menu_element(
						((grandparent.menuguid) ? grandparent.menuguid : menuURL),
						grandparent,
						'back',
						true
					)
				);
			} else {
				inactiveMenuEl.appendChild(
					create_menu_element(
						((grandparent.menuguid) ? grandparent.menuguid : menuURL),
						grandparent,
						'back'
					)
				);
			}
			
		}
		// if grandparent is false, we know we have to render the main menu
		if (grandparent === false) {
			// Aug 2022: commented out so as to not create a Main Menu button

			// inactiveMenuEl.appendChild(
			// 	create_menu_element(
			// 		menuURL,
			// 		({
			// 			id: 0,
			// 			class: false,
			// 			title: false,
			// 			target: false,
			// 			crosslink: null
			// 		}),
			// 		'home'
			// 	)
			// );
		}

		// attach the parent of the menu
		if (parent) {
			inactiveMenuEl.appendChild(create_menu_element(menuURL, parent, "parent"));
		}

		if(children.length > 0){
			let childrenul = document.createElement('ul');

			// attach the children
			children.forEach(child => {
				let childli = document.createElement('li');
				childli.appendChild(
					create_menu_element(menuURL, child, 'child')
				);
				childrenul.appendChild(
					childli
				)
			});	
			inactiveMenuEl.appendChild( childrenul );
		}


		// listen for when the transition ends so the element looses the absolute position.
		inactiveMenuEl.addEventListener('transitionend', () => {
			inactiveMenuEl.classList.remove('supernav-previous', 'supernav-next')
			// focus on the new nav panel 
			inactiveMenuEl.querySelector('a').focus();

		}, { once: true });

		// adding a 1ms delay to allow the menu to render before we animate it.
		window.setTimeout(() => {
			currentMenuEl.classList.add('supernav-inactive');
			currentMenuEl.classList.remove('supernav-current');

			// move the current menu item out of the way
			if (direction === 'forward') {
				currentMenuEl.classList.add('supernav-previous');
			} else {
				currentMenuEl.classList.add('supernav-next');
			}

			// mark the new menu as active, it will now slide into view
			inactiveMenuEl.classList.add('supernav-current');

			// resize the supernav
			window.eventBus.emit('supernav-height-change', supernavs);
			window.eventBus.emit('resize-content-top', supernavs);

		}, 1);
	})
}

// for each supernav, perform a refetch
[].forEach.call(supernavs, supernav => {
	// // console.log( supernav )

	// Init the menu that exists on page, convert links to buttons as needed. 
	const links = supernav.querySelectorAll('a[href="#"],a[href="#/"],button');

	// prefetch our initial menu, only if there are '#' links or buttons we need to act on
	if( links.length > 0){
		fetch_menu(supernav.querySelector('div').dataset.menuguid);
	}


	[].forEach.call(links, item => {

		// convert the a tags into buttons
		let b = document.createElement('button');
		b.classList.value = item.classList.value;
		b.dataset.menuid = item.dataset.menuid;
		b.innerHTML = item.innerHTML;
		b.setAttribute('aria-expanded', 'false');
		if (item.dataset.crosslink) b.dataset.crosslink = item.dataset.crosslink;
		item.after(b);
		item.remove();

		// if the menu item has children, then take over the click event. 
		// if ( b.classList.contains( 'has-child' ) ) {
		b.addEventListener('click', handle_menu_click);
		// }
	});
})

// on resize, set min-height on desktop supernav container
window.addEventListener('resize', e => {
	window.eventBus.emit('supernav-height-change', supernavs);
});