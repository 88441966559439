// https://css-tricks.com/lets-create-a-lightweight-native-event-bus-in-javascript/
var EventBus = function () {
    function EventBus(description) {
      if (description === void 0) {description = '';}
      this.eventTarget = document.appendChild(document.createComment(description));
    }
    EventBus.prototype.on = function (type, listener) {this.eventTarget.addEventListener(type, listener);};
    EventBus.prototype.once = function (type, listener) {this.eventTarget.addEventListener(type, listener, { once: true });};
    EventBus.prototype.off = function (type, listener) {this.eventTarget.removeEventListener(type, listener);};
    EventBus.prototype.emit = function (type, detail) {return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail: detail }));};
    return EventBus;
  }();

export default EventBus;
export { EventBus }